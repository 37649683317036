import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import UniversityIcon from "@/components/Common/Icons/UniversityIcon.vue";

export default {
    name: 'RoommateAdContact',
    props: {
        adtype: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        city: {
            type: String,
            default: ''
        },
        university: {
            type: String,
            default: null
        },
        amount: {
            type: String,
            default: ''
        },
        district: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        }
    },
    components: {
        UniversityIcon,
        Dropdown,
        InputField,
        Button

    },
    mounted() {
    }
}