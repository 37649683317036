<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="city">
    <div class="more-offers-title d-flex align-items-center">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.17383 0.888855L14.7163 8.43133" stroke="#FF7A00" stroke-width="1.33333" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path
            d="M8.42932 2.14593L5.60089 4.97436L4.18668 6.38857L3.47957 7.09568L1.51539 7.80279L7.80078 14.0882L8.50789 12.124L9.21499 11.4169L10.6292 10.0027L13.4576 7.17425"
            stroke="#FF7A00" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.66016 10.9455L0.88892 14.7167" stroke="#FF7A00" stroke-width="1.33333" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
      <span class="ms-2">{{ $t('more-offers-in') }} {{ city.name }}</span>
    </div>
    <div class="row mt-2 mb-2" v-for="post in posts">
      <router-link :to="{name: 'SingleRoommateOffer', params: {id: post.id}}">
        <RoommateAd :adtype="false == true ? 'premium' : ''"
                    :title="post.title"
                    :name="post.name"
                    :city="post.city.name"
                    :location="post.district.name"
                    :university="post.university"
                    :date="post.date"
                    :price="post.price"
                    :description="post.description"
                    :image="post.images.length > 0 ? post.images[0].src : null"
        />
      </router-link>
    </div>
  </div>

</template>
